import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  LOGGEDINUSER,
  UserAgentService,
  User,
  AppInitializerService,
  AppService,
  CannyService,
  ContentfulService,
  GTagService,
  SpeedDialService,
  NavigationHelper,
  PromptDialogService,
  LaunchDarklyService,
  FeatureFlags,
} from '@lc/core';
import { Router } from '@angular/router';
import {
  map, filter, switchMap, take, shareReplay,
} from 'rxjs/operators';
// eslint-disable-next-line
import { Observable } from 'rxjs';
import { environment } from '../../../../libs/environments/environment';
import { options } from './five9.service';

@Component({
  selector: 'lc-agent-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: false,
})
export class AppComponent {
  omniChat$: Observable<Boolean>;
  private readonly GTAG_MAP = new Map([
    ['prod', 'GTM-T6FK2WB'],
    ['uat', 'GTM-MZCLZSD'],
  ]);

  constructor(
    private appInitializer: AppInitializerService,
    private gtagService: GTagService,
    store: Store<any>,
    router: Router,
    _contentfulService: ContentfulService,
    userAgentService: UserAgentService,
    private dialog: PromptDialogService,
    private cannyService: CannyService,
    private speedDialService: SpeedDialService,
    private launchDarklyService: LaunchDarklyService,
  ) {
    userAgentService.doBrowserCheck();
    appInitializer.initializeKeys('lc-agent-app', AppService.productSuiteName);

    this.speedDialService.setSpeedDial({
      five9Config: options,
      feedbackFn: () => router.navigate(['feedback']),
      isProduction: environment.production,
    });

    const user$: Observable<User> = store.select(LOGGEDINUSER).pipe(
      map((user: User) => user),
      shareReplay(1),
    );
    // Set the canny SSO after user logged in
    user$
      .pipe(
        filter((user) => !!user),
        take(1),
      )
      .toPromise()
      .then((user) => {
        this.omniChat$ = this.launchDarklyService.getFeature$(FeatureFlags.OMNI_CHAT, false);
        this.cannyService.getCannySSO$().toPromise();
      });

    // Listen for router changes and notifiy the gtag service when the route changes
    NavigationHelper.urlChanged$(router)
      .pipe(switchMap((url) => user$.pipe(map((user) => ({ user, url })))))
      .subscribe(
        ({ user, url }) => {
          // Update the google tag service
          this.gtagService.updateGTag(this.GTAG_MAP, user, url, 'Agent');

          // Refresh notifications
          this.appInitializer.refreshNotifications(url);
        },
        (error) => {
          throw new Error(error);
        },
      );
  }
}
