import { Component, OnInit } from '@angular/core';
import {
  AppService,
  FeatureFlags,
  LaunchDarklyService,
} from '@lc/core';

declare let ServiceNowChat;

@Component({
  selector: 'service-now-chat',
  templateUrl: './service-now-chat.component.html',
  styleUrls: ['./service-now-chat.component.css'],
  standalone: false,
})
export class ServiceNowChatComponent implements OnInit {
  private window = window;
  private sessionStorage = sessionStorage;
  private serviceNowChatObj: { open: () => void; destroy: () => void; };
  private openChatStorageKey = 'open-chat-on-redirect';
  private snRedirectionCountStorageKey = 'service-now-redirect-count';
  private snRedirectParam = '/sn_va_web_client_login.do?sysparm_redirect_uri=';
  private snVaPath = '/uxasset/externals/now-requestor-chat-popover-app/index.jsdbx?sysparm_substitute=false';
  private serviceNowMcdUrl = null;
  private maxRedirection = 1;
  private brandingKey: string;
  private skipLoadHistory: boolean;
  public serviceNowAuthUrl: string | URL;
  private authPopupWindow: Window | undefined;
  private popupMonitor: NodeJS.Timeout;

  constructor(
    private launchDarklyService: LaunchDarklyService,
  ) { }

  ngOnInit() {
    this.launchDarklyService.getFeature(FeatureFlags.OMNI_CHAT).then((res) => {
      if (res && AppService.get('environment') !== 'local') {
        this.assignServiceNowVariables();
        this.injectSNScript();
      }
    });
  }

  assignServiceNowVariables() {
    this.serviceNowMcdUrl = AppService.get('serviceNowMcdUrl');
    this.brandingKey = AppService.get('serviceNowBrandingKey');
    this.skipLoadHistory = true;
    this.serviceNowAuthUrl = `${this.serviceNowMcdUrl}${this.snRedirectParam}${encodeURIComponent(this.window.location.href)}`;
  }

  injectSNScript() {
    const SNRptPortableVAClientMcdUrl = `${this.serviceNowMcdUrl}${this.snVaPath}`;

    // Check if the script is already injected
    const existingScript = document.querySelector(`script[src="${SNRptPortableVAClientMcdUrl}"]`);
    if (existingScript) {
      console.log('ServiceNow VA script is already injected.');
      // You can handle this case as needed, maybe execute some callback function.
      return;
    }
    const snScript = document.createElement('script');
    snScript.src = SNRptPortableVAClientMcdUrl;
    snScript.type = 'module';
    snScript.async = true;

    snScript.onerror = () => {
      console.error('Error loading ServiceNow VA script.');
    };

    document.getElementsByTagName('head')[0].appendChild(snScript);
    snScript.onload = (this.afterScriptLoaded).bind(this);
  }

  afterScriptLoaded() {
    this.setupServiceNowVA();
    setTimeout(() => {
      this.checkToOpenChat();
    });
  }

  checkToOpenChat() {
    const openChatFlag = this.sessionStorage.getItem(this.openChatStorageKey);
    if (openChatFlag === 'true') {
      this.serviceNowChatObj.open();
      this.sessionStorage.removeItem(this.openChatStorageKey);
    }
  }

  setupServiceNowVA = () => {
    this.serviceNowChatObj = new ServiceNowChat({
      instance: this.serviceNowMcdUrl,
      context: {
        skip_load_history: this.skipLoadHistory,
        branding_key: this.brandingKey,
      },
      branding: {
        bgColor: '#1F1F1F',
        primaryColor: '#FFFFFF',
        hoverColor: '#1F1F1F',
        activeColor: '#AAA',
        closeIcon: 'omni_open_new.svg',
        sizeMultiplier: 1,
      },
      offsetX: 13,
      offsetY: 10,
      position: 'right',
    });
    window.addEventListener('message', this.snEventHandler);
  };

  snEventHandler = (messageEvent) => {
    let redirectionCount = Number(this.sessionStorage.getItem(this.snRedirectionCountStorageKey));
    this.serviceNowAuthUrl = `${this.serviceNowMcdUrl}${this.snRedirectParam}${encodeURIComponent(this.window.location.href)}`;

    /**
     * redirect to SSO login if the web client logs in but is logged in as a guest user(unauthenticated)
     * or, redirect to SSO login if the ServiceNow platform logs out from underneath the web client
     */
    if ((messageEvent.data.type === 'SESSION_CREATED' && messageEvent.data.authenticated === false)
      || messageEvent.data.type === 'SESSION_LOGGED_OUT') {
      redirectionCount += 1;
      if (redirectionCount <= this.maxRedirection) {
        this.sessionStorage.setItem(this.snRedirectionCountStorageKey, redirectionCount.toString());
        this.sessionStorage.setItem(this.openChatStorageKey, 'true');
        this.openAuthPopup();
      } else {
        this.sessionStorage.setItem(this.snRedirectionCountStorageKey, '0');
      }
    } else if (messageEvent.data.type === 'SESSION_CREATED' && messageEvent.data.authenticated === true) {
      this.sessionStorage.setItem(this.snRedirectionCountStorageKey, '0');
      this.authPopupWindow?.close();
    }
  };

  openAuthPopup() {
    const width = 425;
    const height = 650;
    const left = window.outerWidth - width;
    const top = window.outerHeight - height;
    this.authPopupWindow = window.open(
      this.serviceNowAuthUrl,
      'ServiceNowAuthPopup',
      `width=${width},height=${height},top=${top},left=${left},resizable,scrollbars`,
    );
    if (!this.authPopupWindow || this.authPopupWindow.closed) {
      this.window.location.assign(this.serviceNowAuthUrl);
    } else {
      this.monitorPopupForRedirect();
    }
  }

  monitorPopupForRedirect() {
    const expectedRedirectUrlPart = window.location.origin;
    this.popupMonitor = setInterval(() => {
      if (this.authPopupWindow && !this.authPopupWindow.closed) {
        try {
          const popupUrl = this.authPopupWindow.location.href;

          if (popupUrl.includes(expectedRedirectUrlPart)) {
            this.serviceNowChatObj.destroy();
            this.afterScriptLoaded();
            this.authPopupWindow.close();
            clearInterval(this.popupMonitor);
          }
        } catch (error) {
          console.log('An Error Occurred with Omni Chat', error);
        }
      } else {
        clearInterval(this.popupMonitor);
      }
    }, 1000);
  }
}
